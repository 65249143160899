// =========================================================
// * Material Dashboard - v3.0.4
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/material-dashboard
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Bootstrap Functions
@import "bootstrap/functions";

// Custom Variables
@import "custom/variables";

// Theme Variables
@import "/variables";

// Bootstrap Core
@import "bootstrap/bootstrap";

// Theme Core
@import "/theme";

// Custom Variables
@import "custom/styles";
